import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  home: {
    id: 'homepage.navigationTopBar.home',
    defaultMessage: 'Home',
    description: '',
  },
  aboutUs: {
    id: 'homepage.navigationTopBar.aubotUs',
    defaultMessage: 'About Us',
    description: '',
  },
  courses: {
    id: 'homepage.navigationTopBar.courses',
    defaultMessage: 'Courses',
    description: '',
  },
  login: {
    id: 'homepage.navigationTopBar.login',
    defaultMessage: 'Login',
    description: '',
  },
  language: {
    id: 'homepage.navigationTopBar.language',
    defaultMessage: 'Language:',
    description: '',
  },
  learning: {
    id: 'homepage.headerContent.learning',
    defaultMessage: 'Learning',
    description: '',
  },
  managementSystem: {
    id: 'homepage.headerContent.managementSystem',
    defaultMessage: 'Management System',
    description: '',
  },
  guide: {
    id: 'homepage.bodyContent.guide',
    defaultMessage: 'Guide',
    description: '',
  },
  popularCourses: {
    id: 'homepage.bodyContent.popularCourses',
    defaultMessage: 'Popular courses',
    description: '',
  },
  viewMoreCourses: {
    id: 'homepage.bodyContent.viewMoreCourses',
    defaultMessage: 'View more courses',
    description: '',
  },
  Courses: {
    id: 'homepage.courses',
    defaultMessage: 'Courses',
    description: '',
  },
  Search: {
    id: 'homepage.search',
    defaultMessage: 'Search',
    description: '',
  },
  FindYourCourses: {
    id: 'homepage.findYourCourses',
    defaultMessage: 'Find your courses!',
    description: '',
  },
  Course: {
    id: 'homepage.course',
    defaultMessage: 'Course',
    description: '',
  },
  'LMS course': {
    id: 'homepage.lmsCourse',
    defaultMessage: 'LMS course',
    description: '',
  },
  'MOOCS course': {
    id: 'homepage.moocsCourse',
    defaultMessage: 'MOOCS course',
    description: '',
  },
  Lessons: {
    id: 'homepage.lessons',
    defaultMessage: 'Lessons',
    description: '',
  },
  viewAllResults: {
    id: 'homepage.viewAllResult',
    defaultMessage: 'View all results',
    description: '',
  },
  title: {
    id: 'homepage.title',
    defaultMessage: 'Home | {siteName}',
    description: '',
  },
});

export default messages;
