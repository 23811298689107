// This file is generated by the openedx/frontend-platform's "intl-import.js" script.
//
// Refer to the i18n documents in https://docs.openedx.org/en/latest/developers/references/i18n.html to update
// the file and use the Micro-frontend i18n pattern in new repositories.
//

import messagesOfViLanguage from './vi.json';

export default {
  'vi': messagesOfViLanguage,
};
